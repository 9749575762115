<template>
  <section id="filterCheckTable">
    <b-card-actions
      title="선택된 필터 현황"
      action-collapse
    >
      <b-row class="mt-2">
        <b-col
          xl="6"
          md="6"
        >
          <b-table-simple
            hover
            small
            stacked
            bordered
            class="rounded-bottom mb-0"
          >
            <b-tbody>
              <b-tr>
                <b-th class="text-center text-white bg-dark">
                  건축물 대장 정보 필터
                </b-th>
                <b-td stacked-heading="대지점유방식">
                  <b-badge
                    v-for="code in filterFetchedInfo.test"
                    :key="code.cdId"
                    class="mr-1"
                    variant="light-primary"
                  >
                    {{ code.cdNm }}
                  </b-badge>
                </b-td>
                <b-td stacked-heading="소유방식">
                  <b-badge
                    v-for="code in filterFetchedInfo.regstrGbCd"
                    :key="code.cdId"
                    class="mr-1"
                    variant="light-primary"
                  >
                    {{ code.cdNm }}
                  </b-badge>
                </b-td>
                <b-td stacked-heading="소유자유형">
                  <b-badge
                    v-for="code in filterFetchedInfo.test"
                    :key="code.cdId"
                    class="mr-1"
                    variant="light-primary"
                  >
                    {{ code.cdNm }}
                  </b-badge>
                </b-td>
                <b-td stacked-heading="지역유형">
                  <b-badge
                    v-for="code in filterFetchedInfo.kmaAreaCd"
                    :key="code.cdId"
                    class="mr-1"
                    variant="light-primary"
                  >
                    {{ code.cdNm }}
                  </b-badge>
                </b-td>
                <b-td stacked-heading="시도">
                  <b-badge
                    v-for="code in filterFetchedInfo.sidoId"
                    :key="code.sidoId"
                    class="mr-1"
                    variant="light-primary"
                  >
                    {{ code.sidoNm }}
                  </b-badge>
                </b-td>
                <b-td stacked-heading="시군구">
                  <b-badge
                    v-for="code in filterFetchedInfo.sigunguId"
                    :key="code.sigunguId"
                    class="mr-1"
                    variant="light-primary"
                  >
                    {{ code.sigunguNm }}
                  </b-badge>
                </b-td>
                <b-td stacked-heading="용도구분">
                  <b-badge
                    v-for="code in filterFetchedInfo.purpsGbCd"
                    :key="code.cdId"
                    class="mr-1"
                    variant="light-primary"
                  >
                    {{ code.cdNm }}
                  </b-badge>
                </b-td>
                <b-td stacked-heading="주용도">
                  <b-badge
                    v-for="code in filterFetchedInfo.mainPurpsCd"
                    :key="code.cdId"
                    class="mr-1"
                    variant="light-primary"
                  >
                    {{ code.cdNm }}
                  </b-badge>
                </b-td>
                <b-td stacked-heading="대표용도">
                  <b-badge
                    v-for="code in filterFetchedInfo.test"
                    :key="code.cdId"
                    class="mr-1"
                    variant="light-primary"
                  >
                    {{ code.cdNm }}
                  </b-badge>
                </b-td>
                <b-td stacked-heading="세부용도">
                  <b-badge
                    v-for="code in filterFetchedInfo.test"
                    :key="code.cdId"
                    class="mr-1"
                    variant="light-primary"
                  >
                    {{ code.cdNm }}
                  </b-badge>
                </b-td>
                <b-td stacked-heading="층별용도 통일성">
                  <b-badge
                    v-for="code in filterFetchedInfo.test"
                    :key="code.cdId"
                    class="mr-1"
                    variant="light-primary"
                  >
                    {{ code.cdNm }}
                  </b-badge>
                </b-td>
                <b-td stacked-heading="단열기준">
                  <b-badge
                    v-for="code in filterFetchedInfo.insltStCd"
                    :key="code.cdId"
                    class="mr-1"
                    variant="light-primary"
                  >
                    {{ code.cdNm }}
                  </b-badge>
                </b-td>
                <b-td stacked-heading="연식기준">
                  <b-badge
                    v-for="code in filterFetchedInfo.anualStCd"
                    :key="code.cdId"
                    class="mr-1"
                    variant="light-primary"
                  >
                    {{ code.cdNm }}
                  </b-badge></b-td>
                <b-td stacked-heading="연면적구분">
                  <b-badge
                    v-for="code in filterFetchedInfo.totareaCd"
                    :key="code.mgmBldPk"
                    class="mr-1"
                    variant="light-primary"
                  >
                    {{ code.cdNm }}
                  </b-badge>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
        <b-col
          xl="6"
          md="6"
        >
          <b-table-simple
            hover
            small
            stacked
            bordered
            class="rounded-bottom mb-0"
          >
            <colgroup>
              <col style="width: 200px;">
              <col>
            </colgroup>
            <b-tbody>
              <b-tr>
                <b-th
                  class="text-center text-white bg-dark"
                >운영정보 필터</b-th>
                <b-td stacked-heading="건물 입주 업종">
                  <b-badge
                    v-for="(code, index) in filterFetchedInfo.openSvcName"
                    :key="index"
                    class="mr-1"
                    variant="light-primary"
                  >
                    {{ code }}
                  </b-badge>
                </b-td>
                <b-td stacked-heading="연간 운영일수">
                  <b-badge
                    v-for="code in filterFetchedInfo.anualOpdysCd"
                    :key="code.cdId"
                    class="mr-1"
                    variant="light-primary"
                  >
                    {{ code.cdNm }}
                  </b-badge>
                </b-td>
                <b-td stacked-heading="일평균 운영시간">
                  <b-badge
                    v-for="code in filterFetchedInfo.avrgOphrsCd"
                    :key="code.cdId"
                    class="mr-1"
                    variant="light-primary"
                  >
                    {{ code.cdNm }}
                  </b-badge>
                </b-td>
              </b-tr>
              <b-tr>
                <b-th
                  class="text-center text-white bg-dark"
                >에너지 사용량 필터</b-th>
                <b-td stacked-heading="소비연도">
                  <b-badge
                    v-for="code in filterFetchedInfo.useYyCd"
                    :key="code.cdId"
                    class="mr-1"
                    variant="light-primary"
                  >
                    {{ code.cdNm }}
                  </b-badge>
                </b-td>
                <b-td stacked-heading="에너지원">
                  <b-badge
                    v-for="code in filterFetchedInfo.engyKindCd"
                    :key="code.cdId"
                    class="mr-1"
                    variant="light-primary"
                  >
                    {{ code.cdNm }}
                  </b-badge>
                </b-td>
                <b-td stacked-heading="사용용도">
                  <b-badge
                    v-for="code in filterFetchedInfo.clsfKindCd"
                    :key="code.cdId"
                    class="mr-1"
                    variant="light-primary"
                  >
                    {{ code.cdNm }}
                  </b-badge>
                </b-td>
              </b-tr>
              <b-tr>
                <b-th
                  class="text-center text-white bg-dark"
                >시뮬레이션 데이터 필터</b-th>
                <b-td stacked-heading="외피 면적당 도달 일사량">
                  <b-badge
                    v-for="code in filterFetchedInfo.test"
                    :key="code.cdId"
                    class="mr-1"
                    variant="light-primary"
                  >
                    {{ code.cdNm }}
                  </b-badge>
                </b-td>
                <b-td stacked-heading="외피정보">
                  <b-badge
                    v-for="code in filterFetchedInfo.test"
                    :key="code.cdId"
                    class="mr-1"
                    variant="light-primary"
                  >
                    {{ code.cdNm }}
                  </b-badge>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-card-actions>
  </section>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BBadge,
  BRow,
  BCol,
  BTableSimple,
  BTr,
  BTh,
  BTd,
  BTbody,
} from "bootstrap-vue";
import { mapGetters } from "vuex";

export default {
  components: {
    BCardActions,
    BBadge,
    BRow,
    BCol,
    BTableSimple,
    BTr,
    BTh,
    BTd,
    BTbody,
  },
  computed: {
    ...mapGetters({
      filterFetchedInfo: "filter/getFilterFetchedInfo",
    }),
  },
  methods: {},
};
</script>

<style scoped>
.table.b-table.b-table-stacked > tbody > tr > [data-label]::before {width: 25% !important; border-right: 1px solid lightgray !important;}
</style>
