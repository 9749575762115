<template>
  <div class="body-style">
    <div class="">
      <b-button
          size="sm"
          variant="outline-primary"
          @click="opnsvcPopupOpen()"
      >
        업종 검색
      </b-button>
    </div>

    <b-modal
        v-model="modalType"
        centered
        ok-only
        ok-title="확인"
        size="lg"
        title="업종검색"
        @ok="confirmOpnsvcInfo"
    >
      <b-row>
        <b-col>
          <b-form-group label="사업장명">
            <b-form-input
                v-model="bplcNm"
                placeholder="사업장명 입력"
                @keyup.enter="searchOpnsvc"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
              class="align-middle"
              label=" "
          >
            <b-button
                class="ml-1"
                variant="outline-primary"
                @click="searchOpnsvc()"
            >
              <feather-icon icon="SearchIcon" />
              검색
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-alert
              show
              small
              variant="primary"
          >
            <div class="alert-body">
              <span><strong>에너지 사용량 정보</strong>가 있는 인허가 건물만 검색됩니다.</span>
            </div>
          </b-alert>
          <b-overlay
              :show="isBusy"
              class="text-primary"
              rounded="xl"
          >
            <b-table-simple
                bordered
                class="rounded-bottom mb-0"
                hover
                responsive
                sticky-header="450px"
                style="white-space: nowrap;"
            >
              <b-thead head-variant="dark">
                <b-tr class="test-center align-middle">
                  <b-th class="text-center align-middle p-1">
                    <b-button
                        size="sm"
                        variant="outline-light"
                        @click="searchOpnsvcAll()"
                    >
                      <feather-icon
                          class=""
                          icon="CheckIcon"
                      />
                    </b-button>
                  </b-th>
                  <b-th class="text-center align-middle">
                    업종명
                  </b-th>
                  <b-th class="text-center align-middle">
                    사업장명
                  </b-th>
                  <b-th class="text-center align-middle">
                    업태명
                  </b-th>
                  <b-th class="text-center align-middle">
                    지번주소
                  </b-th>
                  <b-th class="text-center align-middle">
                    도로명주소
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody v-if="opnsvcDataList.length > 0">
                <b-tr
                    v-for="(data, index) in opnsvcDataList"
                    :key="index"
                >
                  <b-td class="text-left align-middle p-1">
                    <b-button
                        size="sm"
                        variant="outline-info"
                        @click="selectOpnsvc(data)"
                    >
                      <feather-icon
                          class=""
                          icon="CheckIcon"
                      />
                    </b-button>
                  </b-td>
                  <b-td class="text-left align-middle">
                    {{ data.opnsvcNm }}
                  </b-td>
                  <b-td class="text-left align-middle">
                    {{ data.bplcNm }}
                  </b-td>
                  <b-td class="text-left align-middle">
                    {{ data.uptaeNm }}
                  </b-td>
                  <b-td class="text-left align-middle">
                    {{ data.sitewhlAddr }}
                  </b-td>
                  <b-td class="text-left align-middle">
                    {{ data.rdnwhlAddr }}
                  </b-td>
                </b-tr>
              </b-tbody>
              <b-tbody v-else>
                <b-tr>
                  <b-td
                      class="text-center align-middle"
                      colspan="27"
                      variant="light"
                  >검색된 데이터가 없습니다.
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-overlay>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card-code
              class="mt-2"
              style="max-height: 300px; overflow-y: scroll"
              title="▶ 선택한 업종"
          >
            <b-badge
                v-for="(code, index) in selectedOpnsvc"
                :key="index"
                class="mr-1"
                variant="light-primary"
            >{{ code.bplcNm }}
              <feather-icon
                  class="mr-25"
                  icon="DeleteIcon"
                  style="cursor:pointer;"
                  @click="deleteOpnsvcInfo(code)"
              />
            </b-badge>
          </b-card-code>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BCardCode from "@core/components/b-card-code";
import {
  BAlert,
  BBadge,
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BModal,
  BOverlay,
  BRow,
  BTableSimple,
  BTbody,
  BTd,
  BTh,
  BThead,
  BTr,
  VBModal,
} from "bootstrap-vue";
import { utils } from "@/utils/commUtils";

export default {
  components: {
    BCardCode,
    BButton,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BOverlay,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BBadge,
    BAlert,
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      modalType: false,

      // 사업장명
      bplcNm: "",

      // 선택한 인허가건물
      selectedOpnsvc: [],

      isBusy: false,
    };
  },
  computed: {
    ...mapGetters({
      opnsvcDataList: "filter/getOpnsvcList",
      resultInfo: "filter/getResult",
    }),
  },
  methods: {
    // 팝업 오픈
    opnsvcPopupOpen() {
      this.modalType = !this.modalType;

      // 검색한 업종 정보 초기화
      this.$store.commit("filter/SET_OPNSVC_LIST", []);

      // 선택된 업종 정보 초기화
      this.$store.commit("filter/SET_INIT_SELECTED_COMM_CODE", {
        key: "OPEN_SVC_ID",
        value: [],
      });

      // 팝업에서 사용하는 변수들 초기화
      this.bplcNm = "";
      this.selectedOpnsvc = [];
    },

    // 인허가 건물 검색
    async searchOpnsvc() {
      this.isBusy = true;
      const bplcNm = this.bplcNm;

      await this.$store.dispatch("filter/FETCH_OPNSVC_LIST", { bplcNm });

      this.isBusy = false;
      if (!this.resultInfo.resultFlag) {
        utils.fnAlert(this, this.resultInfo.resultMsg);
      }
    },

    // 업종 전체 선택
    searchOpnsvcAll() {
      if (this.opnsvcDataList.length > 0) {
        if (this.selectedOpnsvc.length === this.opnsvcDataList.length) {
          this.selectedOpnsvc = [];
        } else {
          this.selectedOpnsvc = Array.prototype.slice.call(this.opnsvcDataList);
        }
      }
    },
    // 업종 선택
    selectOpnsvc(obj) {
      const selected = this.selectedOpnsvc;

      // 동일한 데이터를 선택하지 않았을 때만 push함.
      if (!selected.includes(obj)) {
        this.selectedOpnsvc.push(obj);
      }
    },

    // 업종 삭제
    deleteOpnsvcInfo(obj) {
      console.log('delete');
      const selected = this.selectedOpnsvc;

      if (selected.includes(obj)) {
        selected.splice(selected.indexOf(obj), 1);
      }
    },

    // 확인 버튼 클릭시
    confirmOpnsvcInfo() {
      console.log(this.selectedOpnsvc);
      this.$store.commit("filter/SET_INIT_SELECTED_COMM_CODE", {
        key: "OPEN_SVC_ID",
        value: this.selectedOpnsvc,
      });
    },
  },
};
</script>
<style scoped>
.body-style {
  font-family: Helvetica Neue, Arial, sans-serif;
}

input {
  height: auto !important;
}
</style>
