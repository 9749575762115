<template>
  <div>
    <b-button
        class="btn-icon"
        size="md"
        variant="danger"
        @click="deleteFilter()"
    >
      <feather-icon
          icon="MinusCircleIcon"
      />
    </b-button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { BButton, } from "bootstrap-vue";
import { utils } from "@/utils/commUtils";

export default {
  components: {
    BButton,
  },
  computed: {
    ...mapGetters({
      filterSelected: "filter/getFilterFetchedInfo"
    }),
  },
  mounted() {
  },
  methods: {
    // 필터 정보 팝업을 열 때, 공통코드 데이터를 조회함
    deleteFilter() {
      utils.fnConfirm(this, "삭제하시겠습니까?", `[${this.filterSelected.filterNm}] 필터 조건이 삭제됩니다.`)
        .then(async (res) => {
          if (res.value) { // 사용자가 확인 눌럿을 경우
            // 필수 조건 체크(저장 필터명에 값이 있어야 함)
            await this.$store.dispatch("filter/DELETE_FILTER_INFO", this.filterSelected);

            const resFlag = await this.$store.getters["filter/getResult"];
            utils.fnAlert(this, resFlag.resultMsg);
          }
        })
    },
  },
};
</script>

<style lang="scss">
</style>
