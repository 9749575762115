<template>
  <section id="filterCheckTable">
    <b-card-actions action-collapse title="집단별 통계 요약(kWh/㎡)">
      <b-row>
        <b-col>
          <b-form-group label="총량" label-for="engyAllQtyCd">
            <v-select
              id="engyAllQtyCd"
              v-model="filterSelected.engyAllQtyCd"
              :options="commCodeData.ENGY_ALL_QTY_CD"
              label="cdNm"
              placeholder="선택"
              @input="filterSummaryChange()"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="기후정규화(예정)" label-for="test">
            <v-select id="test" :options="[]" disabled placeholder="선택" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="규모 정규화" label-for="totareaGbCd">
            <v-select
              id="totareaGbCd"
              v-model="filterSelected.totareaGbCd"
              :options="commCodeData.TOTAREA_GB_CD"
              label="cdNm"
              placeholder="선택"
              @input="filterSummaryChange()"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-alert show small variant="primary">
            <div class="alert-body">
              <span
                >건물 목록 정보를 확인하시려면
                <code class="downBtn" @click="excelDown()"
                  ><feather-icon icon="DownloadIcon" />엑셀다운로드</code
                >를 이용해주세요.
              </span>
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table-simple bordered class="rounded-bottom mb-0" hover responsive>
            <b-thead head-variant="dark">
              <b-tr>
                <b-th class="text-center align-middle" rowspan="2">
                  표본수
                </b-th>
                <b-th class="text-center align-middle" rowspan="2">
                  평균
                </b-th>
                <b-th class="text-center align-middle" rowspan="2">
                  중앙값(50%)
                </b-th>
                <b-th class="text-center align-middle" colspan="6">
                  백분위
                </b-th>
              </b-tr>
              <b-tr>
                <b-th class="text-center align-middle">
                  1%
                </b-th>
                <b-th class="text-center align-middle">
                  4%
                </b-th>
                <b-th class="text-center align-middle">
                  25%
                </b-th>
                <b-th class="text-center align-middle">
                  75%
                </b-th>
                <b-th class="text-center align-middle">
                  96%
                </b-th>
                <b-th class="text-center align-middle">
                  99%
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-if="filterSummaryData.length > 0">
              <b-tr v-for="(data, index) in filterSummaryData" :key="index">
                <b-td
                  v-if="filterSelected.engyAllQtyCd != null && filterSelected.totareaGbCd != null"
                  class="text-center align-middle"
                >
                  {{ data['cnt'] | makeComma }}
                </b-td>
                <b-td v-else>
                  -
                </b-td>
                <b-td
                  v-if="filterSelected.engyAllQtyCd != null && filterSelected.totareaGbCd != null"
                  class="text-center align-middle"
                >
                  {{ data['avgQty'] | makeComma }}
                </b-td>
                <b-td v-else>
                  -
                </b-td>
                <b-td
                  v-if="filterSelected.engyAllQtyCd != null && filterSelected.totareaGbCd != null"
                  class="text-center align-middle"
                >
                  {{ data['perc50'] | makeComma }}
                </b-td>
                <b-td v-else>
                  -
                </b-td>
                <b-td
                  v-if="filterSelected.engyAllQtyCd != null && filterSelected.totareaGbCd != null"
                  class="text-center align-middle"
                >
                  {{ data['perc01'] | makeComma }}
                </b-td>
                <b-td v-else>
                  -
                </b-td>
                <b-td
                  v-if="filterSelected.engyAllQtyCd != null && filterSelected.totareaGbCd != null"
                  class="text-center align-middle"
                >
                  {{ data['perc04'] | makeComma }}
                </b-td>
                <b-td v-else>
                  -
                </b-td>
                <b-td
                  v-if="filterSelected.engyAllQtyCd != null && filterSelected.totareaGbCd != null"
                  class="text-center align-middle"
                >
                  {{ data['perc25'] | makeComma }}
                </b-td>
                <b-td v-else>
                  -
                </b-td>
                <b-td
                  v-if="filterSelected.engyAllQtyCd != null && filterSelected.totareaGbCd != null"
                  class="text-center align-middle"
                >
                  {{ data['perc75'] | makeComma }}
                </b-td>
                <b-td v-else>
                  -
                </b-td>
                <b-td
                  v-if="filterSelected.engyAllQtyCd != null && filterSelected.totareaGbCd != null"
                  class="text-center align-middle"
                >
                  {{ data['perc96'] | makeComma }}
                </b-td>
                <b-td v-else>
                  -
                </b-td>
                <b-td
                  v-if="filterSelected.engyAllQtyCd != null && filterSelected.totareaGbCd != null"
                  class="text-center align-middle"
                >
                  {{ data['perc99'] | makeComma }}
                </b-td>
                <b-td v-else>
                  -
                </b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-else>
              <b-tr>
                <b-td class="text-center align-middle" colspan="9" variant="light"
                  >검색된 데이터가 없습니다.
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-card-actions>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import vSelect from 'vue-select';
import { utils } from '@/utils/commUtils';
import { BAlert, BCol, BFormGroup, BRow, BTableSimple, BTbody, BTd, BTh, BThead, BTr } from 'bootstrap-vue';

export default {
  components: {
    BCardActions,
    vSelect,

    BAlert,
    BTableSimple,
    BFormGroup,
    BRow,
    BCol,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
  },
  computed: {
    ...mapGetters({
      filterSummaryData: 'filter/getDataSummary',
      commCodeData: 'filter/getCommCodeData',
      filterSelected: 'filter/getFilterFetchedInfo',
      result: 'filter/getResult',
    }),
  },
  methods: {
    // 필터 조건 변경 이벤트
    filterSummaryChange() {
      const { engyAllQtyCd } = this.filterSelected;
      const { totareaGbCd } = this.filterSelected;

      // 총량과 규모 정규화가 모두 선택되었을 경우에만 조회함
      if (engyAllQtyCd !== null && totareaGbCd !== null) {
        // 집단별 통계 요약 테이블 조회
        const filter = this.filterSelected;
        this.$store.dispatch('filter/FETCH_FILTER_SUMMARY_DATA', filter);
      }
    },
    // 건물 목록 엑셀 다운로드
    excelDown() {
      // utils.fnAlert(this, '다운로드 권한이 없습니다');
      utils
        .fnConfirm(
          this,
          '다운로드 하시겠습니까?',
          '표본수 <strong>1만건 이상</strong>인 데이터 다운로드 시 <br/>오랜 시간이 소요됩니다.',
        )
        .then(async (result) => {
          if (result.value) {
            const { filterSelected } = this;
            await this.$store.dispatch('filter/DOWNLOAD_EXCEL', filterSelected);
            if (!this.result.resultFlag) {
              utils.fnAlert(this, this.result.resultMsg);
            }
          }
        });
    },
  },
};
</script>

<style scoped>
.downBtn {
  cursor: pointer;
}

.downBtn:hover {
  background-color: #28c76f;
  color: #ffffff;
}
</style>
