<template>
  <section id="filterCheckTable">
    <b-card-actions
      title="원단위-연면적 사용량 차트(kWh/㎡)"
      action-collapse
    >
      <filter-box-plot-chart />
    </b-card-actions>
  </section>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import FilterBoxPlotChart from "./FilterBoxPlotChart.vue"

export default {
  components: {
    BCardActions,

    // chart
    FilterBoxPlotChart,
  },

};
</script>
