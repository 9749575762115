<template>
  <b-form-group>
    <v-select
      ref="filterNm"
      v-model="selected"
      label="filterNm"
      placeholder="필터 정보를 선택하세요."
      :options="filterInfo"
      class="bg-white"
      @input="fetchData($event)"
    />
  </b-form-group>
</template>

<script>
import vSelect from 'vue-select';
import { BFormGroup } from "bootstrap-vue";
import { mapGetters } from 'vuex';

export default {
  components: {
    vSelect,
    BFormGroup,
  },
  data() {
    return {
      selected: {},
    }
  },
  computed: {
    ...mapGetters({
      filterInfo: "filter/getFilterInfo", // 필터 정보
      // filterSelected: "filter/getFilterFetchedInfo"
    }),
    filterSelected: {
      get() {
        return this.$store.state["filter/filterFetchedInfo"];
      },
      set(newVal) {
        this.$store.state["filter/filterFetchedInfo"] = newVal;
      }
    }
  },
  watch: {
    filterInfo(val) {
      this.selected = val[0];
      this.fetchData(this.selected);
    }
  },
  created() {
    // 저장된 필터 정보 조회
    this.$store.dispatch("filter/FETCH_FILTER_INFO");
  },
  methods: {
    async fetchData(e) {
      this.selected = e;
      const filterData = e;
      // 필터 정보 조회
      await this.$store.dispatch("filter/FETCH_FILTER_DATA", filterData);

      // 집단별 통계 요약 - 총량, 규모정규화 공통코드 조회
      await this.$store.dispatch("filter/FETCH_COMM_CODE_DATA_SUMMARY");
    }
  }
}
</script>

<style scoped>

</style>
