<template>
  <div>
    <b-button
        v-b-toggle.searchForm
        size="sm"
        variant="warning"
        @click="openFilterSearchForm()"
    >
      <feather-icon
          class="mr-50"
          icon="SettingsIcon"
      />
      <span class="align-middle">필터설정</span>
    </b-button>
    <b-sidebar
        id="searchForm"
        ref="searchForm"
        backdrop
        bg-variant="white"
        shadow
        width="600px"
    >
      <!-- types -->
      <!-- thpe은 default, shadow, margin, border에서 선택할 수 있음-->
      <app-collapse
          :type="'default'"
          accordion
          class="p-2"
      >
        <b-form @submit.prevent>
          <app-collapse-item
              :is-visible="true"
              title="건축물 대장 정보 필터"
          >
            <b-row>
              <!-- 왼쪽 select -->
              <b-col md="6">
                <b-form-group
                    label="대지점유방식(예정)"
                    label-for="platOcpCd"
                >
                  <v-select
                      id="platOcpCd"
                      v-model="filterInfo.test"
                      :disabled="true"
                      :options="[]"
                      label="title"
                      multiple
                      placeholder="선택"
                  />
                </b-form-group>
                <b-form-group
                    label="소유자 유형(예정)"
                    label-for="ownerTypeCd"
                >
                  <v-select
                      id="ownerTypeCd"
                      v-model="filterInfo.test"
                      :disabled="true"
                      :options="[]"
                      label="cdNm"
                      multiple
                      placeholder="선택"
                  />
                </b-form-group>
                <b-form-group
                    label="시도"
                    label-for="sido"
                >
                  <v-select
                      id="sido"
                      v-model="filterInfo.SIDO_ID"
                      :options="sidoList"
                      :selectable="() => filterInfo.SIDO_ID.length < 1"
                      label="sidoNm"
                      multiple
                      placeholder="선택"
                      @input="sidoChange($event)"
                  />
                </b-form-group>
                <b-form-group
                    label="용도구분(예정)"
                    label-for="purpsGbCd"
                >
                  <v-select
                      id="purpsGbCd"
                      v-model="filterInfo.test"
                      :disabled="true"
                      :options="[]"
                      label="cdNm"
                      multiple
                      placeholder="선택"
                  />
                </b-form-group>
                <b-form-group
                    label="대표용도(예정)"
                    label-for="test"
                >
                  <v-select
                      id="test"
                      v-model="filterInfo.test"
                      :disabled="true"
                      :options="[]"
                      label="cdNm"
                      multiple
                      placeholder="선택"
                  />
                </b-form-group>
                <b-form-group
                    label="층별 용도 통일성(예정)"
                    label-for="test"
                >
                  <v-select
                      id="test"
                      v-model="filterInfo.test"
                      :disabled="true"
                      :options="[]"
                      label="cdNm"
                      multiple
                      placeholder="선택"
                  />
                </b-form-group>
                <b-form-group
                    label="연식기준"
                    label-for="anualStCd"
                >
                  <v-select
                      id="anualStCd"
                      v-model="filterInfo.ANUAL_ST_CD"
                      :options="commCodeData.ANUAL_ST_CD"
                      label="cdNm"
                      multiple
                      placeholder="선택"
                  />
                </b-form-group>
              </b-col>

              <!-- 오른쪽 select -->
              <b-col md="6">
                <b-form-group
                    label="소유방식"
                    label-for="regstrGbCd"
                >
                  <v-select
                      id="regstrGbCd"
                      v-model="filterInfo.REGSTR_GB_CD"
                      :options="commCodeData.REGSTR_GB_CD"
                      :selected="'1'"
                      label="cdNm"
                      multiple
                      placeholder="선택"
                  />
                </b-form-group>
                <b-form-group
                    label="지역유형"
                    label-for="kmaAreaCd"
                >
                  <v-select
                      id="kmaAreaCd"
                      v-model="filterInfo.KMA_AREA_CD"
                      :options="commCodeData.KMA_AREA_CD"
                      label="cdNm"
                      multiple
                      placeholder="선택"
                  />
                </b-form-group>
                <b-form-group
                    label="시군구"
                    label-for="sigungu"
                >
                  <v-select
                      id="sigungu"
                      v-model="filterInfo.SIGUNGU_ID"
                      :options="sigunguList"
                      label="sigunguNm"
                      multiple
                      placeholder="선택"
                  />
                </b-form-group>
                <b-form-group
                    label="주용도"
                    label-for="mainPurpsCd"
                >
                  <v-select
                      id="mainPurpsCd"
                      v-model="filterInfo.MAIN_PURPS_CD"
                      :options="commCodeData.MAIN_PURPS_CD"
                      label="cdNm"
                      multiple
                      placeholder="선택"
                  />
                </b-form-group>
                <b-form-group
                    label="세부용도(예정)"
                    label-for="test"
                >
                  <v-select
                      id="test"
                      v-model="filterInfo.test"
                      :disabled="true"
                      :options="[]"
                      label="cdNm"
                      multiple
                      placeholder="선택"
                  />
                </b-form-group>
                <b-form-group
                    label="단열기준"
                    label-for="insltStCd"
                >
                  <v-select
                      id="insltStCd"
                      v-model="filterInfo.INSLT_ST_CD"
                      :options="commCodeData.INSLT_ST_CD"
                      label="cdNm"
                      multiple
                      placeholder="선택"
                  />
                </b-form-group>
                <b-form-group
                    label="연면적구분"
                    label-for="totareaCd"
                >
                  <v-select
                      id="totareaCd"
                      v-model="filterInfo.TOTAREA_CD"
                      :options="commCodeData.TOTAREA_CD"
                      label="cdNm"
                      multiple
                      placeholder="선택"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </app-collapse-item>

          <app-collapse-item title="건축물 운영 정보 필터">
            <b-row>
              <!-- 왼쪽 select -->
              <b-col>
                <b-form-group
                    label="건물 입주 업종"
                    label-for="useYyCd"
                >
                  <filter-opnsvc-popup />
                </b-form-group>

                <b-card
                    v-if="filterInfo.OPEN_SVC_ID.length > 0"
                    :border-variant="'primary'"
                    :size="'sm'"
                    class="shadow-none mt-1 mb-1 align-content-center"
                    style="max-height: 300px; overflow-y: scroll"
                >
                  <b-badge
                      v-for="(code, index) in filterInfo.OPEN_SVC_ID"
                      :key="index"
                      class="mr-1"
                      variant="light-primary"
                  >{{ code.bplcNm }}
                  </b-badge>
                </b-card>

              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group
                    label="연간 운영 일수(예정)"
                    label-for="anualOpdysCd"
                >
                  <v-select
                      id="anualOpdysCd"
                      v-model="filterInfo.test"
                      :disabled="true"
                      :options="[]"
                      label="cdNm"
                      multiple
                      placeholder="선택"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                    label="일평균 운영시간(예정)"
                    label-for="avrgOphrsCd"
                >
                  <v-select
                      id="avrgOphrsCd"
                      v-model="filterInfo.test"
                      :disabled="true"
                      :options="[]"
                      label="cdNm"
                      multiple
                      placeholder="선택"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </app-collapse-item>

          <app-collapse-item title="에너지 사용량 필터">
            <b-row>
              <!-- 왼쪽 select -->
              <b-col md="6">
                <b-form-group
                    label="소비연도"
                    label-for="useYyCd"
                >
                  <v-select
                      id="useYyCd"
                      v-model="filterInfo.USE_YY_CD"
                      :options="commCodeData.USE_YY_CD"
                      label="cdNm"
                      multiple
                      placeholder="선택"
                  />
                </b-form-group>
                <b-form-group
                    label="사용용도"
                    label-for="clsfKindCd"
                >
                  <v-select
                      id="clsfKindCd"
                      v-model="filterInfo.CLSF_KIND_CD"
                      :options="commCodeData.CLSF_KIND_CD"
                      label="cdNm"
                      multiple
                      placeholder="선택"
                  />
                </b-form-group>
              </b-col>

              <!-- 오른쪽 select -->
              <b-col md="6">
                <b-form-group
                    label="에너지원"
                    label-for="engyKindCd"
                >
                  <v-select
                      id="engyKindCd"
                      v-model="filterInfo.ENGY_KIND_CD"
                      :options="commCodeData.ENGY_KIND_CD"
                      label="cdNm"
                      multiple
                      placeholder="선택"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </app-collapse-item>

          <app-collapse-item title="시뮬레이션 데이터 필터">
            <b-row>
              <!-- 왼쪽 select -->
              <b-col md="6">
                <b-form-group
                    label="외피 면적당 도달 일사량(예정)"
                    label-for="test"
                >
                  <v-select
                      id="test"
                      v-model="filterInfo.test"
                      :disabled="true"
                      :options="[]"
                      label="cdNm"
                      multiple
                      placeholder="선택"
                  />
                </b-form-group>
              </b-col>

              <!-- 오른쪽 select -->
              <b-col md="6">
                <b-form-group
                    label="외피 정보(예정)"
                    label-for="test"
                >
                  <v-select
                      id="test"
                      v-model="filterInfo.test"
                      :disabled="true"
                      :options="[]"
                      label="cdNm"
                      multiple
                      placeholder="선택"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </app-collapse-item>
          <b-row class="mt-2">
            <b-col>
              <div class="form-label-group">
                <b-form-input
                    id="filterName"
                    ref="filterName"
                    v-model="filterInfo.FILTER_NM"
                    autocomplete="off"
                    class="border-primary"
                    placeholder="저장할 필터명을 입력하세요."
                />
                <label for="filterName">저장할 필터명을 입력하세요.</label>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div>
                <b-button
                    block
                    variant="primary"
                    @click="saveFilter()"
                >
                  필터 저장 후 조회
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </app-collapse>
    </b-sidebar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

import { BBadge, BButton, BCard, BCol, BForm, BFormGroup, BFormInput, BRow, BSidebar, VBToggle } from "bootstrap-vue";
import vSelect from "vue-select";
import { utils } from "@/utils/commUtils";
import FilterOpnsvcPopup from "./FilterOpnsvcPopup.vue";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BSidebar,
    BFormInput,
    BBadge,
    BCard,

    AppCollapse,
    AppCollapseItem,
    vSelect,

    FilterOpnsvcPopup, // 업종 검색 팝업
  },
  directives: {
    'b-toggle': VBToggle,
  },
  computed: {
    ...mapGetters({
      commCodeData: "filter/getCommCodeData",
      filterInfo: "filter/getSaveFilterInfo",
      sidoList: "filter/getSidoList",
      sigunguList: "filter/getSigunguList",
      resultInfo: "filter/getResult",
    }),
  },
  mounted() {
  },
  methods: {
    // 필터 정보 팝업을 열 때, 공통코드 데이터를 조회함
    openFilterSearchForm() {
      // 저장할 필터 정보 초기화
      this.$store.commit("filter/SET_RESET_SAVE_FILTER_INFO");

      // 공통코드 불러오기
      this.$store.dispatch("filter/FETCH_COMM_CODE_DATA");

      // 시도 정보 불러오기
      this.$store.dispatch("filter/FETCH_SIDO_LIST");
    },
    // 시도 변경시 시군구 데이터 조회
    sidoChange(event) {
      if (event.length !== 0) {
        // 선택된 값이 있는 경우 sigungu 데이터 조회
        const sidoInfo = event[0];
        this.$store.dispatch("filter/FETCH_SIGUNGU_LIST", sidoInfo.sidoId);

        if (!this.resultInfo.resultFlag) {
          utils.fnAlert(this, this.resultInfo.resultMsg);
        }
      } else {
        // 선택된 값이 없는 경우 선택된 sigungu 초기화
        this.$store.dispatch("filter/FETCH_SIGUNGU_LIST", null);
      }
    },
    // 필터 정보 저장
    saveFilter() {
      utils.fnConfirm(this, "저장하시겠습니까?", "설정된 필터 조건들이 저장됩니다.")
        .then(async (res) => {
          if (res.value) { // 사용자가 확인 눌럿을 경우
            // 필터 정보 저장
            await this.$store.dispatch("filter/SAVE_FILTER_INFO", this.filterInfo);
            const resFlag = await this.$store.getters["filter/getResult"];
            utils.fnAlert(this, resFlag.resultMsg);

            if (resFlag.resultFlag) {
              this.$refs.searchForm.hide();
            }
            // // 필수 조건 체크(저장 필터명에 값이 있어야 함)
            // if (this.filterInfo.FILTER_NM === "" || this.filterInfo.FILTER_NM === undefined) {
            //   utils.fnAlert(this, "저장할 필터명을 입력해주세요.");
            // } else {
            // }
          }
        })
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
