<template>
  <div>
    <!-- 상단 버튼부 -->
    <b-row>
      <b-col>
        <filter-list />
      </b-col>
      <b-col class="text-right">
        <service-button :current="'filter-service'" />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="4"
        class="align-middle pr-1"
      >
        <filter-select-box />
      </b-col>
      <b-col
        sm="1"
        class="p-0"
      >
        <filter-delete-button />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col>
        <b-overlay
          class="text-primary"
          :show="show"
          rounded="xl"
        >
          <filter-check-table />
          <filter-static-summary-table />
          <filter-chart />
          <!--          <FilterStaticTable />-->
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { BRow, BCol, BOverlay } from "bootstrap-vue";
import ServiceButton from "../../layouts/button/ServiceButton.vue"
import FilterSearchForm from "./FilterSearchForm.vue";
import FilterCheckTable from "./FilterCheckTable.vue";
import FilterStaticSummaryTable from "./FilterStaticSummaryTable.vue";
import FilterChart from "./FilterChart.vue";
import FilterSelectBox from "./FilterSelectBox.vue";
import FilterDeleteButton from "./FilterDeleteButton.vue";
import FilterList from "./FilterList.vue";
// import FilterStaticTable from "./FilterStaticTable.vue";

export default {
  components: {
    BRow,
    BCol,
    BOverlay,
    ServiceButton,

    FilterList,
    FilterSearchForm,
    FilterCheckTable,
    FilterStaticSummaryTable,
    FilterChart,
    FilterSelectBox,
    FilterDeleteButton,
    // FilterStaticTable,
  },
  computed: {
    ...mapGetters({
      show: "filter/getShowOverlay",
    }),
  },
  mounted() {
     // gis delete..
  }
};
</script>

<style></style>
